<template>
  <div class="container with-price-box">
    <div>
      <h1>Iberico Vorderbein (Paleta)</h1>
      <p>
        Dies ist das Vorderbein des Iberico-Schweins. Es ist kleiner als das Hinterbein (Schinken) und hat eine andere
        Form.
      </p>
      <p>Das Gesamtgewicht liegt zwischen 4 und 6 Kilogramm. Familien in Spanien haben normalerweise ein solches Bein in
        der Küche und essen es über die Feiertage sehr schnell auf.</p>
      <p>
        Da sich die Schweine mit süßen Eicheln gemästet haben, hat der Schinken ein ausgeprägtes nussiges Aroma, das an
        den
        Geschmack der Eicheln erinnert. Es ist ein Stück Dehesa auf Ihrem Tisch. Es passt auch gut zu einem Glas Rotwein.
      </p>

      <p>Hier ist ein Video, das zeigt, wie die Schweine aufgezogen wurden und wie der Schinken hergestellt wird:</p>

      <CookieControlledContent>
        <iframe data-cy="youtubeVideo" class="youtube-video" width="460" height="258"
          src="https://www.youtube.com/embed/1xHDWjk90sc?si=QCoQir0t9T8tUcsI" title="YouTube-Videoplayer" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </CookieControlledContent>

      <picture v-if="!$cookieConsent.accepted()">
        <a href="/img/shop/2023-Ham-Promo-1080.jpg"><img src="@/assets/images/2023-Ham-Promo-250.jpg" /></a>
      </picture>

    </div>
    <PriceBox product="c55eb01b-0d1e-4f9d-9af6-7d16e6678496" />
  </div>
</template>

<script>
import PriceBox from '@/components/PriceBox.vue'
import CookieControlledContent from '@/components/CookieControlledContent.vue'

export default {
  name: "LegOfHam",
  components: {
    PriceBox, CookieControlledContent
  },
}
</script>
